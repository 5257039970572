import React, { ReactNode, useContext, useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { ModalLocalProps } from "@/components/shared/types";
import Button from "@/components/web/buttons/Button";
import Checkbox from "@/components/web/checkboxes/Checkbox";
import InputField from "@/components/web/inputs/InputField";
import store from "stores/store";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import IconOutsideLink from "../icons/IconOutsideLink";
import { TrackingContext } from "@/components/shared/tracking/Tracking";
import Markdown from "react-markdown";

type ActiveCheckbox = string | null;

const ModalLocal: React.FC<ModalLocalProps> = ({ isOpen, onClose, checkedBox }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const radioButtons = [
        {
            key: startLocalized?.cardOneRadioButtonOne ?? "In einer Filiale",
            label: startLocalized?.modalFirstRadioButton ?? "In einer Filiale",
        },
        {
            key: startLocalized?.cardOneRadioButtonTwo ?? "Am Telefon",
            label: startLocalized?.modalSecondRadioButton ?? "Am Telefon",
        },
        {
            key: startLocalized?.cardOneRadioButtonThree ?? "Per Mail",
            label: startLocalized?.modalThirdRadioButton ?? "Per Mail",
        },
    ];

    const [activeCheckbox, setActiveCheckbox] = useState<ActiveCheckbox>(null);
    const [activeCheckboxNoBank, setActiveCheckboxNoBank] = useState<ActiveCheckbox>(null);
    const [dataPrivacyCheck, setDataPrivacyCheck] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [results, setResults] = useState<any[]>([]);
    const [locationChecked, setLocationChecked] = useState(null);
    const [locationCheckedString, setLocationCheckedString] = useState("");
    const [send, setSend] = useState<boolean>(false);
    const [isCustomer, setCustomer] = useState<boolean | null>(null);
    const [isNotCustomer, setIsNotCustomer] = useState<boolean | null>(null);

    const setIsSparkassenCustomer = () => {
        if (isCustomer) {
            return true;
        } else if (isNotCustomer) {
            return false;
        } else {
            return false;
        }
    };

    const [customerData, setCustomerData] = useState({
        firstName: "",
        lastName: "",
        location: "",
        eMail: "",
    });

    let participatingBuildingsArray: string[] = [];

    const participatingBuildings = () => {
        startLocalized?.participatingSparkassen &&
            startLocalized?.participatingSparkassen.map(
                (building) => building?.title !== undefined && participatingBuildingsArray.push(building?.title),
            );
        return participatingBuildings;
    };

    participatingBuildings();

    useEffect(() => {
        if (inputValue !== "") {
            fetchBuildings(inputValue);
        }
    }, [inputValue]);

    const nassauPostalCodes = [
        "65183",
        "65195",
        "65191",
        "65199",
        "65197",
        "65205",
        "65201",
        "65193",
        "65185",
        "65189",
        "65187",
        "65203",
        "60306",
        "60308",
        "60310",
        "60311",
        "60313",
        "60314",
        "60323",
        "60325",
        "60327",
        "60329",
        "60386",
        "60326",
        "60316",
        "60318",
        "60322",
        "60486",
        "60487",
        "60489",
        "60385",
        "60320",
        "60389",
        "60431",
        "60433",
        "60435",
        "60438",
        "60439",
        "60488",
        "60528",
        "60594",
        "60596",
        "60598",
        "60599",
        "60529",
        "65835",
        "65929",
        "65931",
        "65933",
        "65934",
        "65936",
        "65207",
        "65205",
        "65795",
        "65760",
        "65812",
        "65843",
        "65824",
        "65830",
        "65439",
        "65239",
        "65719",
        "65817",
        "65779",
        "65527",
        "613881",
        "61348",
        "61350",
        "61352",
        "61440",
        "61462",
        "61476",
        "61479",
        "61267",
        "61389",
        "61250",
        "61276",
        "61279",
        "61273",
        "65510",
        "65529",
        "65232",
        "65388",
        "65307",
        "65321",
        "65326",
        "65329",
        "65366",
        "65375",
        "65385",
        "65391",
        "65347",
        "65346",
        "65345",
        "65344",
        "65399",
        "65396",
        "65343",
        "65520",
        "65611",
        "65618",
        "65589",
        "65620",
        "65627",
        "65599",
        "65604",
        "65549",
        "65550",
        "65551",
        "65552",
        "65553",
        "65554",
        "65555",
        "65556",
        "65597",
        "56337",
        "56130",
        "56132",
        "56133",
        "56377",
        "65623",
        "65629",
        "56368",
        "56370",
        "56379",
        "65558",
        "65582",
        "65624",
        "65626",
        "56338",
        "56340",
        "56341",
        "56112",
        "56346",
        "56348",
        "56349",
        "56355",
        "56357",
        "56249",
        "56269",
        "56242",
        "56237",
        "56203",
        "56204",
        "56206",
        "56170",
        "56235",
        "56335",
        "56410",
        "56412",
        "56422",
        "56424",
        "56427",
        "56428",
        "56244",
        "56477",
        "56479",
        "57612",
        "57614",
        "57627",
        "57629",
        "57642",
        "57644",
        "57645",
        "56271",
        "57648",
        "57647",
        "57520",
        "57583",
        "56470",
        "56472",
        "56459",
        "56462",
        "56457",
        "56414",
    ];

    function normalizeString(str: string) {
        return str
            .toLowerCase()
            .replace(/-/g, " ")
            .replace(/\s+/g, "")
            .replace(/[^\w\s]/gi, "");
    }

    const fetchBuildings = async (searchTerm: string) => {
        let baseUrl;

        if (nassauPostalCodes.includes(searchTerm)) {
            //baseUrl = `https://institute-finder.sparkasse.de/institutes/search?query=${encodeURIComponent("Nassau")}`;
            baseUrl = `https://institute-finder.sparkasse.de/institutes/search?query=${encodeURIComponent(searchTerm.trimEnd())}`;
        } else {
            baseUrl = `https://institute-finder.sparkasse.de/institutes/search?query=${encodeURIComponent(searchTerm.trimEnd())}`;
        }

        try {
            const response = await fetch(baseUrl, {
                method: "GET",
                headers: {
                    "X-Api-Key": "0731d82b-384f-11ea-bddb-0050568b24f3",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            if (data.institutes && Array.isArray(data.institutes.items)) {
                const filterBuildings = data.institutes.items.filter((building: any) =>
                    participatingBuildingsArray.some(
                        (participatingName) => normalizeString(building.name) === normalizeString(participatingName),
                    ),
                );

                const buildings = filterBuildings.slice(0, 3);
                const nassauObject = { bin: 51050015, name: "Nassauische Sparkasse" };

                if (
                    nassauPostalCodes.includes(searchTerm) &&
                    !buildings.some(
                        (building: any) => building.bin === nassauObject.bin && building.name === nassauObject.name,
                    )
                ) {
                    buildings.push(nassauObject);
                }

                setResults(buildings);
            } else {
                console.error("Expected data.institutes.items to be an array, but got:", typeof data.institutes.items);
            }

            setShowDropdown(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults([]);
            setShowDropdown(false);
        }
    };

    const parseTextWithLinks = (text: string) => {
        const linkRegex = /\[url\|(.*?)\|([^|]+?)\|([^|\]]+)\]/g;
        let match: RegExpExecArray | null;
        const output: ReactNode[] = [];
        let lastIndex = 0;

        while ((match = linkRegex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                output.push(text.slice(lastIndex, match.index));
            }

            const [, url, linkText, iconName] = match;
            output.push(
                <a
                    href={url}
                    key={match.index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#DD0B2F", textDecoration: "none" }}
                >
                    {linkText}
                    {iconName && <IconOutsideLink width="12" height="12" />}
                </a>,
            );
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < text.length) {
            output.push(text.slice(lastIndex));
        }

        return output;
    };

    const resetData = () => {
        setCustomerData({
            ...customerData,
            ["firstName"]: "",
            ["lastName"]: "",
            ["location"]: "",
            ["eMail"]: "",
        });
        setInputValue("");
        setShowDropdown(false);
        setResults([]);
        setDataPrivacyCheck(false);
        setLocationChecked(null);
        setSend(false);
        setActiveCheckbox("");
        setIsNotCustomer(false);
        setCustomer(false);
        setActiveCheckboxNoBank(null);
    };

    const onCloseModal = () => {
        resetData();
        onClose();
    };

    const handleInputChange = (name: string, value: string) => {
        setCustomerData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (option: ActiveCheckbox) => {
        const customerCheck = isCustomer;
        resetData();
        if (customerCheck) {
            setCustomer(true);
        } else {
            setIsNotCustomer(true);
        }
        setActiveCheckbox((prevState) => (prevState === option ? prevState : option));
    };

    const handleNoSparkassenCheckboxes = (option: ActiveCheckbox) => {
        setActiveCheckboxNoBank((prevState) => (prevState === option ? prevState : option));
    };

    const handleLocationChange = (index: any, item: any) => {
        setLocationChecked(index);
        setLocationCheckedString(item);
        console.log(locationChecked);
    };

    const isSendButtonEnabled = () => {
        const { firstName, lastName, eMail } = customerData;

        if (isCustomer && locationChecked !== null) {
            return true;
        }
        if (isNotCustomer && activeCheckbox === startLocalized?.cardOneRadioButtonOne && locationChecked !== null) {
            return true;
        }
        if (isNotCustomer && activeCheckbox === startLocalized?.cardOneRadioButtonTwo) return true;
        if (
            isNotCustomer &&
            activeCheckbox === startLocalized?.cardOneRadioButtonThree &&
            dataPrivacyCheck &&
            firstName &&
            lastName &&
            eMail
        )
            return true;
        if (isCustomer && activeCheckboxNoBank === startLocalized?.cardOneRadioButtonTwo) return true;
        if (
            isCustomer &&
            activeCheckboxNoBank === startLocalized?.cardOneRadioButtonThree &&
            firstName &&
            lastName &&
            eMail &&
            dataPrivacyCheck
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isButtonVisible = () => {
        if (isCustomer && locationChecked !== null) {
            return true;
        }
        if (isNotCustomer && activeCheckbox === startLocalized?.cardOneRadioButtonOne) {
            return true;
        }
        if (isNotCustomer && activeCheckbox === startLocalized?.cardOneRadioButtonTwo) return true;
        if (isNotCustomer && activeCheckbox === startLocalized?.cardOneRadioButtonThree) return true;
        if (isCustomer && activeCheckboxNoBank === startLocalized?.cardOneRadioButtonTwo) {
            return true;
        }
        if (isCustomer && activeCheckboxNoBank === startLocalized?.cardOneRadioButtonThree) {
            return true;
        } else {
            return false;
        }
    };

    function sendEmail(firstName: string, lastName: string, eMail: string, isSparkassenCustomer: boolean) {
        const url = "https://nl-api.curious.zone/api/mail/";
        const data = {
            firstName: firstName,
            lastName: lastName,
            eMail: eMail,
            isSparkassenCustomer: isSparkassenCustomer,
        };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => console.log(response.json()))
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleSend = () => {
        if (
            activeCheckbox === startLocalized?.cardOneRadioButtonOne ||
            activeCheckboxNoBank === startLocalized?.cardOneRadioButtonOne
        ) {
            const checkedBuilding = startLocalized?.participatingSparkassen.find(
                (item) => item && normalizeString(item.title) === normalizeString(locationCheckedString),
            );
            checkedBuilding && window.open(checkedBuilding.link ?? "/", "_blank");
            onCloseModal();
            trackSubmit("filial-finder", normalizeString(locationCheckedString));
        }
        if (isCustomer && locationChecked !== null) {
            const checkedBuilding = startLocalized?.participatingSparkassen.find(
                (item) => item && normalizeString(item.title) === normalizeString(locationCheckedString),
            );
            checkedBuilding && window.open(checkedBuilding.link ?? "/", "_blank");
            onCloseModal();
            trackSubmit("filial-finder", normalizeString(locationCheckedString));
        }
        if (
            activeCheckbox === startLocalized?.cardOneRadioButtonTwo ||
            activeCheckboxNoBank === startLocalized?.cardOneRadioButtonTwo
        ) {
            window.open(startLocalized?.modalButtonVideoCall.link ?? "/", "_blank");
            onCloseModal();
            trackSubmit("video-call");
        }
        if (
            activeCheckbox === startLocalized?.cardOneRadioButtonThree ||
            activeCheckboxNoBank === startLocalized?.cardOneRadioButtonThree
        ) {
            sendEmail(customerData.firstName, customerData.lastName, customerData.eMail, setIsSparkassenCustomer());
            setSend(true);
            trackSubmit("e-mail");
        }
    };

    const { trackSubmit, trackCTA } = useContext(TrackingContext);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-[99999999] flex h-[calc(100dvh)] items-center justify-center overflow-y-auto">
            <div
                className="fixed h-[calc(120dvh)] w-full overflow-y-hidden bg-black bg-opacity-50"
                onClick={onCloseModal}
            />
            <div className={cn("absolute flex w-full flex-col", "2xs:w-[448px]", "md:w-[680px]")}>
                <div
                    className={cn(
                        "flex w-full justify-between gap-[16px] border border-b-0 border-blackShade-15 bg-red-primary px-[32px] py-[24px]",
                        "2xs:rounded-tl-[24px] 2xs:rounded-tr-[24px]",
                        "md:rounded-tl-[16px] md:rounded-tr-[16px]",
                        [send && "rounded-[16px] text-center"],
                    )}
                >
                    <div className="font-frutigerBlackItalic text-[28px] uppercase leading-[110%]">
                        {!send && startLocalized?.modalTitle}
                        {send && startLocalized?.modalSuccessfullySubmitted}
                    </div>
                    <button
                        onClick={onCloseModal}
                        className="relative h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px]"
                    >
                        <ExportedImage
                            src={
                                startLocalized?.modalCloseIcon !== undefined && startLocalized?.modalCloseIcon !== null
                                    ? getImageUrlFromGeneralImage(startLocalized.modalCloseIcon)
                                    : "No image provided"
                            }
                            alt={
                                startLocalized?.modalCloseIcon !== undefined && startLocalized?.modalCloseIcon !== null
                                    ? getAltTextFromGeneralImage(startLocalized.modalCloseIcon)
                                    : "No image provided"
                            }
                            layout="fill"
                            objectPosition="center"
                        />
                    </button>
                </div>
                {!send && (
                    <div
                        className={cn(
                            "flex w-full flex-col gap-[24px] border border-blackShade-15 bg-white px-[32px] py-[24px]",
                            "2xs:rounded-[24px] 2xs:rounded-tl-none 2xs:rounded-tr-none",
                            "md::rounded-[16px] xl:rounded-tl-none xl:rounded-tr-none",
                        )}
                    >
                        <div className="flex flex-col gap-[12px]">
                            <div className="font-frutigerBlackItalic text-base font-extrabold uppercase leading-[110%] text-neutral-900">
                                Bist du bereits Kunde einer sparkasse ?
                            </div>
                            <div className="flex flex-col gap-[24px]">
                                <div className="flex gap-[32px] rounded-[16px] border border-blackShade-15 p-[16px]">
                                    <Checkbox
                                        label="Test"
                                        checked={isCustomer !== null && isCustomer}
                                        onChange={() => {
                                            resetData();
                                            setCustomer(true);
                                            trackCTA("is-customer", false);
                                        }}
                                        className="text-black"
                                    >
                                        Ja
                                    </Checkbox>
                                    <Checkbox
                                        label="Test"
                                        checked={isNotCustomer !== null && isNotCustomer}
                                        onChange={() => {
                                            resetData();
                                            setIsNotCustomer(true);
                                            trackCTA("is-not-customer", false);
                                        }}
                                        className="text-black"
                                    >
                                        Nein
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                        {isCustomer && (
                            <div className="flex flex-col gap-[16px]">
                                <div className="font-frutigerBlackItalic text-base font-extrabold uppercase leading-[110%] text-neutral-900">
                                    {startLocalized?.modalLocationInputTitle}
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    <InputField
                                        placeholder={
                                            startLocalized?.modalLocationInputPlaceholder !== undefined
                                                ? startLocalized?.modalLocationInputPlaceholder
                                                : ""
                                        }
                                        icon={
                                            <ExportedImage
                                                src={
                                                    startLocalized?.modalLocationInputIcon !== undefined &&
                                                    startLocalized?.modalLocationInputIcon !== null
                                                        ? getImageUrlFromGeneralImage(
                                                              startLocalized.modalLocationInputIcon,
                                                          )
                                                        : "No image provided"
                                                }
                                                alt={
                                                    startLocalized?.modalLocationInputIcon !== undefined &&
                                                    startLocalized?.modalLocationInputIcon !== null
                                                        ? getAltTextFromGeneralImage(
                                                              startLocalized.modalLocationInputIcon,
                                                          )
                                                        : "No image provided"
                                                }
                                                layout="fill"
                                            />
                                        }
                                        value={inputValue}
                                        onChange={(e) => {
                                            setInputValue(e.target.value),
                                                setLocationChecked(null),
                                                setActiveCheckbox(null),
                                                setActiveCheckboxNoBank(null);
                                        }}
                                    />
                                    {showDropdown &&
                                        inputValue.length > 0 &&
                                        (results.length > 0 ? (
                                            <ul className="overflow-auto text-black">
                                                {results.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className="cursor-pointer border-b p-2 last:border-b-0 hover:bg-neutral-50"
                                                    >
                                                        <Checkbox
                                                            key={index}
                                                            label={item.label}
                                                            checked={locationChecked === index}
                                                            onChange={() => handleLocationChange(index, item.name)}
                                                            className="text-black"
                                                        >
                                                            {item.name}
                                                        </Checkbox>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div className="flex flex-col gap-[24px]">
                                                <div className="font-frutigerRoman text-[12px] leading-[135%] text-red-primary xl:text-[16px]">
                                                    {startLocalized && (
                                                        <Markdown>
                                                            {startLocalized.modalSparkasseNotFoundError}
                                                        </Markdown>
                                                    )}
                                                </div>
                                                <div className="flex flex-col gap-[12px]">
                                                    <div className="font-frutigerBlackItalic text-base uppercase leading-[110%] text-neutral-900">
                                                        {startLocalized?.modalCheckboxesTitle}
                                                    </div>
                                                    <div className="flex flex-col gap-[16px] rounded-2xl border border-blackShade-15 p-[16px]">
                                                        {radioButtons.map((radioButton, index) => {
                                                            if (
                                                                radioButton.label ===
                                                                startLocalized?.modalFirstRadioButton
                                                            ) {
                                                                return;
                                                            }

                                                            return (
                                                                <Checkbox
                                                                    key={radioButton.key}
                                                                    label={radioButton.label}
                                                                    checked={activeCheckboxNoBank === radioButton.key}
                                                                    onChange={() =>
                                                                        handleNoSparkassenCheckboxes(radioButton.key)
                                                                    }
                                                                >
                                                                    {radioButton.label}
                                                                </Checkbox>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                {activeCheckboxNoBank === startLocalized?.cardOneRadioButtonTwo && (
                                                    <div className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-900 xl:text-[16px]">
                                                        Die Beratung erfolgt über unseren Partner, die Sparkasse
                                                        Vorpommern.
                                                    </div>
                                                )}
                                                {activeCheckboxNoBank === startLocalized?.cardOneRadioButtonThree && (
                                                    <>
                                                        <div className="flex flex-col gap-[24px]">
                                                            <div className="flex flex-col gap-[12px]">
                                                                <InputField
                                                                    placeholder={
                                                                        startLocalized?.modalFirstInputPlaceholder !==
                                                                        undefined
                                                                            ? startLocalized?.modalFirstInputPlaceholder
                                                                            : ""
                                                                    }
                                                                    required
                                                                    value={customerData.firstName}
                                                                    onChange={(e) =>
                                                                        handleInputChange("firstName", e.target.value)
                                                                    }
                                                                />
                                                                <InputField
                                                                    placeholder={
                                                                        startLocalized?.modalSecondInputPlaceholder !==
                                                                        undefined
                                                                            ? startLocalized?.modalSecondInputPlaceholder
                                                                            : ""
                                                                    }
                                                                    required
                                                                    value={customerData.lastName}
                                                                    onChange={(e) =>
                                                                        handleInputChange("lastName", e.target.value)
                                                                    }
                                                                />
                                                                <InputField
                                                                    placeholder={
                                                                        startLocalized?.modalMailInputPlaceholder !==
                                                                        undefined
                                                                            ? startLocalized?.modalMailInputPlaceholder
                                                                            : ""
                                                                    }
                                                                    required
                                                                    value={customerData.eMail}
                                                                    onChange={(e) =>
                                                                        handleInputChange("eMail", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                            <Checkbox
                                                                label="Ich bin damit einverstanden,..."
                                                                className="text-base"
                                                                checked={dataPrivacyCheck}
                                                                onChange={() => setDataPrivacyCheck(!dataPrivacyCheck)}
                                                            >
                                                                <p>
                                                                    {parseTextWithLinks(
                                                                        startLocalized?.modalDatasecurityCheckbox,
                                                                    )}
                                                                </p>
                                                            </Checkbox>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                        {isNotCustomer && (
                            <>
                                <div className="flex flex-col gap-[12px]">
                                    <div className="font-frutigerBlackItalic text-base uppercase leading-[110%] text-neutral-900">
                                        {startLocalized?.modalCheckboxesTitle}
                                    </div>
                                    <div className="flex flex-col gap-[16px] rounded-2xl border border-blackShade-15 p-[16px]">
                                        {radioButtons.map((radioButton, index) => {
                                            return (
                                                <Checkbox
                                                    key={radioButton.key}
                                                    label={radioButton.label}
                                                    checked={activeCheckbox === radioButton.key}
                                                    onChange={() => handleCheckboxChange(radioButton.key)}
                                                >
                                                    {radioButton.label}
                                                </Checkbox>
                                            );
                                        })}
                                    </div>
                                </div>
                                {activeCheckbox === startLocalized?.cardOneRadioButtonOne && (
                                    <div className="flex flex-col gap-[16px]">
                                        <div className="font-frutigerBlackItalic text-base font-extrabold uppercase leading-[110%] text-neutral-900">
                                            Filialfinder
                                        </div>
                                        <div className="flex flex-col gap-[12px]">
                                            <InputField
                                                placeholder={
                                                    startLocalized?.modalLocationInputPlaceholder !== undefined
                                                        ? startLocalized?.modalLocationInputPlaceholder
                                                        : ""
                                                }
                                                icon={
                                                    <ExportedImage
                                                        src={
                                                            startLocalized?.modalLocationInputIcon !== undefined &&
                                                            startLocalized?.modalLocationInputIcon !== null
                                                                ? getImageUrlFromGeneralImage(
                                                                      startLocalized.modalLocationInputIcon,
                                                                  )
                                                                : "No image provided"
                                                        }
                                                        alt={
                                                            startLocalized?.modalLocationInputIcon !== undefined &&
                                                            startLocalized?.modalLocationInputIcon !== null
                                                                ? getAltTextFromGeneralImage(
                                                                      startLocalized.modalLocationInputIcon,
                                                                  )
                                                                : "No image provided"
                                                        }
                                                        layout="fill"
                                                    />
                                                }
                                                value={inputValue}
                                                onChange={(e) => {
                                                    setInputValue(e.target.value),
                                                        setLocationChecked(null),
                                                        setActiveCheckboxNoBank(null);
                                                }}
                                            />
                                            {showDropdown &&
                                                inputValue.length > 0 &&
                                                (results.length > 0 ? (
                                                    <ul className="overflow-auto text-black">
                                                        {results.map((item, index) => (
                                                            <li
                                                                key={index}
                                                                className="cursor-pointer border-b p-2 last:border-b-0 hover:bg-neutral-50"
                                                            >
                                                                <Checkbox
                                                                    key={index}
                                                                    label={item.label}
                                                                    checked={locationChecked === index}
                                                                    onChange={() =>
                                                                        handleLocationChange(index, item.name)
                                                                    }
                                                                    className="text-black"
                                                                >
                                                                    {item.name}
                                                                </Checkbox>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <div className="font-frutigerRoman text-[12px] leading-[135%] text-red-primary xl:text-[16px]">
                                                        <Markdown>
                                                            Leider nimmt diese Sparkasse nicht an der Aktion teil. Du
                                                            kannst dich trotzdem via Video-Call beraten lassen oder
                                                            weitere Informationen per E-Mail anfordern.
                                                        </Markdown>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                                {activeCheckbox === startLocalized?.cardOneRadioButtonTwo && (
                                    <div className="font-frutigerRoman text-[12px] leading-[135%] text-neutral-900 xl:text-[16px]">
                                        Die Beratung erfolgt über unseren Partner, die Sparkasse Vorpommern.
                                    </div>
                                )}
                                {activeCheckbox === startLocalized?.cardOneRadioButtonThree && (
                                    <>
                                        <div className="flex flex-col gap-[24px]">
                                            <div className="flex flex-col gap-[12px]">
                                                <InputField
                                                    placeholder={
                                                        startLocalized?.modalFirstInputPlaceholder !== undefined
                                                            ? startLocalized?.modalFirstInputPlaceholder
                                                            : ""
                                                    }
                                                    required
                                                    value={customerData.firstName}
                                                    onChange={(e) => handleInputChange("firstName", e.target.value)}
                                                />
                                                <InputField
                                                    placeholder={
                                                        startLocalized?.modalSecondInputPlaceholder !== undefined
                                                            ? startLocalized?.modalSecondInputPlaceholder
                                                            : ""
                                                    }
                                                    required
                                                    value={customerData.lastName}
                                                    onChange={(e) => handleInputChange("lastName", e.target.value)}
                                                />
                                                <InputField
                                                    placeholder={
                                                        startLocalized?.modalMailInputPlaceholder !== undefined
                                                            ? startLocalized?.modalMailInputPlaceholder
                                                            : ""
                                                    }
                                                    required
                                                    value={customerData.eMail}
                                                    onChange={(e) => handleInputChange("eMail", e.target.value)}
                                                />
                                            </div>
                                            <Checkbox
                                                label="Ich bin damit einverstanden,..."
                                                className="text-base"
                                                checked={dataPrivacyCheck}
                                                onChange={() => setDataPrivacyCheck(!dataPrivacyCheck)}
                                            >
                                                <p>{parseTextWithLinks(startLocalized?.modalDatasecurityCheckbox)}</p>
                                            </Checkbox>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {isButtonVisible() && (
                            <Button
                                onClick={handleSend}
                                className="max-w-[322px] gap-4 text-[14px]"
                                rightIcon={
                                    activeCheckbox !== startLocalized?.cardOneRadioButtonThree ? (
                                        <ExportedImage
                                            src={
                                                startLocalized?.modalButtonConsulting.icon !== undefined &&
                                                startLocalized?.modalButtonConsulting.icon !== null
                                                    ? getImageUrlFromGeneralImage(
                                                          startLocalized.modalButtonConsulting.icon,
                                                      )
                                                    : "No image provided"
                                            }
                                            alt={
                                                startLocalized?.modalButtonConsulting.icon !== undefined &&
                                                startLocalized?.modalButtonConsulting.icon !== null
                                                    ? getAltTextFromGeneralImage(
                                                          startLocalized.modalButtonConsulting.icon,
                                                      )
                                                    : "No image provided"
                                            }
                                            layout="fill"
                                            objectPosition="center"
                                        />
                                    ) : (
                                        <ExportedImage
                                            src={
                                                startLocalized?.modalButtonSendMail.icon !== undefined &&
                                                startLocalized?.modalButtonSendMail.icon !== null
                                                    ? getImageUrlFromGeneralImage(
                                                          startLocalized.modalButtonSendMail.icon,
                                                      )
                                                    : "No image provided"
                                            }
                                            alt={
                                                startLocalized?.modalButtonSendMail.icon !== undefined &&
                                                startLocalized?.modalButtonSendMail.icon !== null
                                                    ? getAltTextFromGeneralImage(
                                                          startLocalized.modalButtonSendMail.icon,
                                                      )
                                                    : "No image provided"
                                            }
                                            layout="fill"
                                            objectPosition="center"
                                        />
                                    )
                                }
                                disabledIcon={
                                    activeCheckbox !== startLocalized?.cardOneRadioButtonThree ? (
                                        <ExportedImage
                                            src={
                                                startLocalized?.modalButtonConsulting.disabledIcon !== undefined &&
                                                startLocalized?.modalButtonConsulting.disabledIcon !== null
                                                    ? getImageUrlFromGeneralImage(
                                                          startLocalized.modalButtonConsulting.disabledIcon,
                                                      )
                                                    : "No image provided"
                                            }
                                            alt={
                                                startLocalized?.modalButtonConsulting.disabledIcon !== undefined &&
                                                startLocalized?.modalButtonConsulting.disabledIcon !== null
                                                    ? getAltTextFromGeneralImage(
                                                          startLocalized.modalButtonConsulting.disabledIcon,
                                                      )
                                                    : "No image provided"
                                            }
                                            layout="fill"
                                            objectPosition="center"
                                        />
                                    ) : (
                                        <ExportedImage
                                            src={
                                                startLocalized?.modalButtonSendMail.disabledIcon !== undefined &&
                                                startLocalized?.modalButtonSendMail.disabledIcon !== null
                                                    ? getImageUrlFromGeneralImage(
                                                          startLocalized.modalButtonSendMail.disabledIcon,
                                                      )
                                                    : "No image provided"
                                            }
                                            alt={
                                                startLocalized?.modalButtonSendMail.disabledIcon !== undefined &&
                                                startLocalized?.modalButtonSendMail.disabledIcon !== null
                                                    ? getAltTextFromGeneralImage(
                                                          startLocalized.modalButtonSendMail.disabledIcon,
                                                      )
                                                    : "No image provided"
                                            }
                                            layout="fill"
                                            objectPosition="center"
                                        />
                                    )
                                }
                                disabled={!isSendButtonEnabled()}
                            >
                                {activeCheckbox !== startLocalized?.cardOneRadioButtonThree
                                    ? startLocalized?.modalButtonConsulting.text
                                    : startLocalized?.modalButtonSendMail.text}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalLocal;
