import { cn, useLG, useMD } from "@/lib/utils";
import { inject, observer } from "mobx-react";
import ExportedImage from "next-image-export-optimizer";
import { useContext, useEffect, useState } from "react";
import store from "stores/store";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import ButtonSecondary from "../buttons/ButtonSecondary";
import CardOne from "../landingpage/CardOne";
import { TrackingContext } from "@/components/shared/tracking/Tracking";
type HeroProps = {
    onCall: () => void;
    onTutorial: () => void;
};

const Hero: React.FC<HeroProps> = ({ onCall, onTutorial }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const MD = useMD();
    const LG = useLG();

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getImageSize = () => {
        if (windowWidth < 480) {
            return startLocalized?.hero320px;
        } else if (windowWidth < 768) {
            return startLocalized?.hero480px;
        } else if (windowWidth < 1024) {
            return startLocalized?.hero768px;
        } else if (windowWidth < 1280) {
            return startLocalized?.hero1024px;
        } else if (windowWidth < 1920) {
            return startLocalized?.hero1280px;
        } else if (windowWidth >= 1920) {
            return startLocalized?.hero1920px;
        }
    };

    const hero = getImageSize();

    const { trackCTA } = useContext(TrackingContext);

    return (
        <div className={cn("relative flex h-[581px] w-full justify-center", "md:h-[469px]", "lg:h-[544px]")}>
            {hero !== undefined && hero !== null && (
                <div className={cn("relative h-full w-full")}>
                    <ExportedImage
                        src={getImageUrlFromGeneralImage(hero)}
                        alt={getAltTextFromGeneralImage(hero)}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            )}
            <div
                className={cn(
                    "absolute left-[-15px] top-0 flex w-full px-[16px]",
                    "3xs:top-[25px]",
                    "2xs:left-0 2xs:px-[32px]",
                    "md:left-auto md:max-w-[705px] md:px-0",
                    "lg:max-w-[912px] lg:justify-end",
                    "xl:max-w-[1108px]",
                )}
            >
                {startLocalized?.claim !== undefined && startLocalized.claim !== null && (
                    <div
                        className={cn(
                            "relative left-0 right-auto top-[25px] h-[120px] w-[181px]",
                            "2xs:left-0 2xs:top-0 2xs:h-[176px] 2xs:w-[266px]",
                            "lg:left-auto lg:right-0 lg:h-[165px] lg:w-[260px]",
                        )}
                    >
                        <ExportedImage
                            src={getImageUrlFromGeneralImage(startLocalized?.claim)}
                            alt={getAltTextFromGeneralImage(startLocalized?.claim)}
                            layout="fill"
                            objectFit="cover"
                        />
                    </div>
                )}
            </div>

            <div
                className={cn(
                    "absolute bottom-[-119px] flex w-full flex-col gap-[24px] px-[16px]",
                    "2xs:bottom-[-37px] 2xs:px-[32px]",
                    "md:gap[16px] md:bottom-[40px] md:max-w-[705px] md:flex-row md:items-end md:justify-between md:px-0",
                    "lg:top-[117px] lg:max-w-[912px] lg:items-end lg:gap-[30px]",
                    "xl:max-w-[1108px] xl:gap-[124px]",
                    "2xl:gap-[125px]",
                )}
            >
                <div className="flex flex-col gap-[16px]">
                    <div className={cn("flex flex-col gap-[4px]")}>
                        <div
                            className={cn(
                                "font-frutigerBlackItalic text-[16px] uppercase leading-[110%]",
                                "lg:text-[24px]",
                            )}
                        >
                            {startLocalized?.heroTitle}
                        </div>
                        <div
                            className={cn(
                                "font-frutigerBlackItalic text-[24px] uppercase leading-[110%]",
                                "lg:text-[40px]",
                            )}
                        >
                            {startLocalized?.heroTitleBold}
                        </div>
                    </div>
                    <div className={cn("flex flex-col gap-[14px]")}>
                        <div
                            className={cn(
                                "font-frutigerRoman text-[12px] leading-[135%] tracking-minus-2",
                                "lg:text-[18px]",
                            )}
                        >
                            {startLocalized?.heroContent}
                        </div>
                        <div className="flex flex-col gap-[16px]">
                            <ButtonSecondary
                                onClick={() => {
                                    onCall();
                                    trackCTA("jetzt-zukunfts-ich-anrufen");
                                }}
                                rightIcon={
                                    <ExportedImage
                                        src={
                                            startLocalized?.heroButton.icon !== undefined &&
                                            startLocalized?.heroButton.icon !== null
                                                ? getImageUrlFromGeneralImage(startLocalized.heroButton.icon)
                                                : "No image provided"
                                        }
                                        alt={
                                            startLocalized?.heroButton.icon !== undefined &&
                                            startLocalized?.heroButton.icon !== null
                                                ? getAltTextFromGeneralImage(startLocalized.heroButton.icon)
                                                : "No image provided"
                                        }
                                        layout="fill"
                                        objectPosition="center"
                                    />
                                }
                            >
                                {startLocalized?.heroButton.text}
                            </ButtonSecondary>
                            {/* <button
                                onClick={onTutorial}
                                className="w-full text-center font-frutigerBold text-[12px] leading-[110%] underline"
                            >
                                {startLocalized?.heroLink}
                            </button> */}
                        </div>
                    </div>
                </div>
                <CardOne
                    className={cn(
                        "md:relative md:bottom-[-88px] md:h-min md:max-h-min md:w-[345px] md:min-w-[345px] md:max-w-[345px]",
                        "lg:relative lg:top-[127px] lg:max-h-[276px] lg:w-[441px] lg:min-w-[441px] lg:max-w-[441px]",
                        "xl:top-[70px] xl:w-[539px] xl:min-w-[539px] xl:max-w-[539px]",
                    )}
                />
            </div>
        </div>
    );
};

export default inject("store")(observer(Hero));
