import { inject, observer } from "mobx-react";
import store from "stores/store";
import CardWhite from "../cards/CardWhite";
import Checkbox from "../checkboxes/Checkbox";
import Button from "../buttons/Button";
import ExportedImage from "next-image-export-optimizer";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";
import { cn } from "@/lib/utils";
import { useContext, useState } from "react";
import ModalLocal from "../modals/ModalLocal";
import { TrackingContext } from "@/components/shared/tracking/Tracking";

type CardOneProps = {
    className?: string;
};

type ActiveCheckbox = string | null;

const CardOne: React.FC<CardOneProps> = ({ className }) => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized } = store;

    const radioButtons = [
        {
            key: startLocalized?.cardOneRadioButtonOne ?? "In einer Sparkasse",
            label: startLocalized?.cardOneRadioButtonOne ?? "In einer Sparkasse",
        },
        {
            key: startLocalized?.cardOneRadioButtonTwo ?? "via Video-Call",
            label: startLocalized?.cardOneRadioButtonTwo ?? "via Video-Call",
        },
        {
            key: startLocalized?.cardOneRadioButtonThree ?? "per E-Mail",
            label: startLocalized?.cardOneRadioButtonThree ?? "per E-Mail",
        },
    ];

    const [activeCheckbox, setActiveCheckbox] = useState<ActiveCheckbox>(radioButtons[0].label);

    const handleCheckboxChange = (option: ActiveCheckbox) => {
        setActiveCheckbox((prevState) => (prevState === option ? prevState : option));
    };

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        if (activeCheckbox === radioButtons[0].label || activeCheckbox === radioButtons[2].label) {
            setModalOpen(true);
        } else {
            window.open(startLocalized?.modalButtonVideoCall.link ?? "/", "_blank");
        }
    };

    const { trackCTA } = useContext(TrackingContext);

    return (
        <>
            <CardWhite
                className={cn(
                    "gap-[16px] px-[20px] pb-[24px] pt-[20px]",
                    "lg:items-end lg:gap-[24px] lg:p-[32px]",
                    className,
                )}
            >
                <div
                    className={cn(
                        "font-frutigerBlack text-[16px] uppercase italic leading-[110%] text-red-primary",
                        "lg:text-[20px]",
                    )}
                >
                    {startLocalized?.cardOneTitle}
                </div>
                <div className="flex h-full w-full flex-col justify-between gap-[16px] lg:gap-[24px]">
                    <Button
                        onClick={() => {
                            handleSubmit(), trackCTA("jetzt-beraten-lassen-1");
                        }}
                        className={cn(
                            "py-[6px] pl-[16px] pr-[6px] text-[14px]",
                            "lg:py-[12px] lg:text-[16px]",
                            "xl:w-[275px] xl:min-w-[275px]",
                        )}
                        rightIcon={
                            <ExportedImage
                                src={
                                    startLocalized?.cardOneButton.icon !== undefined &&
                                    startLocalized?.cardOneButton.icon !== null
                                        ? getImageUrlFromGeneralImage(startLocalized.cardOneButton.icon)
                                        : "No image provided"
                                }
                                alt={
                                    startLocalized?.cardOneButton.icon !== undefined &&
                                    startLocalized?.cardOneButton.icon !== null
                                        ? getAltTextFromGeneralImage(startLocalized.cardOneButton.icon)
                                        : "No image provided"
                                }
                                layout="fill"
                                objectPosition="center"
                            />
                        }
                    >
                        {startLocalized?.cardOneButton.text}
                    </Button>
                </div>
            </CardWhite>
            <ModalLocal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} checkedBox={activeCheckbox} />
        </>
    );
};

export default inject("store")(observer(CardOne));
