import * as React from "react";

import { cn } from "@/lib/utils";

import { CheckboxProps } from "@/components/shared/types";
import IconCheck from "../icons/IconCheck";

const Checkbox: React.FC<CheckboxProps> = ({ checked, children, onChange, textColor, className }) => {
    return (
        <label className={cn("flex cursor-pointer items-center gap-[8px]", className)}>
            <input
                type="checkbox"
                className="sr-only"
                checked={checked}
                onChange={onChange} // Übergebe direkt das Event
            />
            <div
                className={cn(
                    "flex h-[24px] max-h-[24px] min-h-[24px] w-[24px] min-w-[24px] max-w-[24px] flex-shrink-0 items-center justify-center rounded-full border-2 border-red-primary",
                    { "bg-red-primary": checked },
                )}
            >
                {checked && <IconCheck color="#FFFFFF" />}
            </div>
            <div
                className={cn(
                    "select-none font-frutigerRoman text-[12px] leading-[135%] text-black",
                    "lg:text-[12px]",
                    "xl:text-[16px]",
                    textColor,
                )}
            >
                {children}
            </div>
        </label>
    );
};

export default Checkbox;
